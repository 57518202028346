import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
// styles
import "assets/css/bootstrap-5.0.2-dist/css/bootstrap.min.css";
import "assets/css/paper-kit.css";
// import "assets/css/paper-kit.min.css";
// import "assets/css/paper-kit.css.map";
import "assets/demo/demo.css";


import Index from "views/Index.js";
import LandingPage from "views/LandingPage.js";
import RegisterPage from "views/RegisterPage.js";
import Gochat from "views/gochat.js"
import MyPage from 'views/mypage.js';
import Userguide from 'views/userguide';
import Feedback from 'views/feedback';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/index" element={<Index />} />
      <Route path="/landing-page" element={<LandingPage />} />
      <Route path="/register-page" element={<RegisterPage />} />
      <Route path="/gochat" element = {<Gochat/>} />
      <Route path="/mypage" element = {<MyPage/>} />
      <Route path="/userguide" element = {<Userguide/>} />
      <Route path="/feedback" element = {<Feedback/>} />
      <Route path="*" element={<Navigate to="/index" replace />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
