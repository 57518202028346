import React, { useState, useEffect } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import axios from 'axios';
import '../mypage.css'; // Make sure to create this CSS file and include the styles

function MyPage() {
  const [age, setAge] = useState('');
  const [location, setLocation] = useState('');
  const [gender, setGender] = useState('');
  const [firestoreData, setFirestoreData] = useState(null);
  const [airtableData, setAirtableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toggle, setToggle] = useState(false); // New state for toggle
  const [airtableData1, setAirtableData1] = useState([]);

  useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  }, []);

  useEffect(() => {
    const fetchFirestoreData = async () => {
      setLoading(true);
      setError(null);
      const storedEmail = localStorage.getItem('email');
      const storedGmail = localStorage.getItem('googleEmail');
      const loginValue = storedEmail || storedGmail;

      if (loginValue) {
        try {
          const userDocRef = doc(db, 'chatbot', loginValue);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data();
            setFirestoreData(data);
            setAge(data.age || '');
            setLocation(data.location || '');
            setGender(data.gender || '');
          } else {
            setError("No such document exists!");
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
          setError("Error fetching document");
        }
      } else {
        setError("User not logged in");
      }
      setLoading(false);
    };

    fetchFirestoreData();
  }, []);

  useEffect(() => {
    const fetchAirtableData = async () => {
      if (!firestoreData) {
        return;
      }

      const API_KEY = 'patnRD968AdtGn7OQ.9c32906bb81cff11cb2b8b0ab0d90a4b0303a0247e6de678049a9dcd687bb306';
      const BASE_ID = 'appun8EtILHoURFGw';
      const TABLE_NAME = 'Sheet1';
      const targetCondition = (firestoreData.age <= 18)
        ? 'SEARCH("자립준비", {target})'
        : 'NOT(SEARCH("자립준비", {target}))';

      try {
        const filterByFormula = `AND(OR({region} = '전국', {region} = '${location}'), ${targetCondition})`;
        const response = await axios.get(`https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`, {
          headers: {
            Authorization: `Bearer ${API_KEY}`
          },
          params: {
            filterByFormula: filterByFormula
          }
        });
        console.log(filterByFormula);

        const sortedData = response.data.records.sort((a, b) => {
          if (a.fields.whether === "모집중" && b.fields.whether !== "모집중") {
            return -1;
          } else if (a.fields.whether !== "모집중" && b.fields.whether === "모집중") {
            return 1;
          } else {
            return 0;
          }
        });

        setAirtableData(sortedData);
      } catch (error) {
        console.error("Error fetching Airtable data: ", error);
        setError("Error fetching Airtable data");
      }
    };

    fetchAirtableData();
  }, [firestoreData, location, age]); // Fetch data when firestoreData, location, or age changes

  useEffect(() => {
    const fetchAirtableData1 = async () => {
      if (!firestoreData) {
        return;
      }

      const API_KEY = 'patnRD968AdtGn7OQ.9c32906bb81cff11cb2b8b0ab0d90a4b0303a0247e6de678049a9dcd687bb306';
      const BASE_ID = 'appktUp2y7r1FIPGz';
      const TABLE_NAME = 'Imported%20table';
      try {
        const filterByFormula = `OR({region} = '전국', {region} = '${location}')`;
        const response = await axios.get(`https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`, {
          headers: {
            Authorization: `Bearer ${API_KEY}`
          },
          params: {
            filterByFormula: filterByFormula
          }
        });
        console.log(filterByFormula);

        // Sort the data so that records with "모집종료" in "whether" field come first
        

        setAirtableData1(response.data.records);
      } catch (error) {
        console.error("Error fetching Airtable data: ", error);
        setError("Error fetching Airtable data");
      }
    };

    fetchAirtableData1();
  }, [firestoreData, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const storedEmail = localStorage.getItem('email');
    const storedGmail = localStorage.getItem('googleEmail');
    const loginValue = storedEmail || storedGmail;

    if (!loginValue) {
      alert("Login value is required.");
      return;
    }

    const updateData = {};
    if (age) updateData.age = age;
    if (location) updateData.location = location;
    if (gender) updateData.gender = gender;

    if (Object.keys(updateData).length === 0) {
      alert('No information to update.');
      return;
    }

    try {
      const userDocRef = doc(db, 'chatbot', loginValue);
      await setDoc(userDocRef, updateData, { merge: true });
      setFirestoreData(prevFirestoreData => ({
        ...prevFirestoreData,
        ...updateData
      }));
      alert('정보가 성공적으로 제출되었습니다!');
    } catch (error) {
      console.error("Error adding document: ", error);
      setError("Error adding document");
      alert('오류가 발생했습니다. 잠시 후에 다시 시도해주세요.');
    }
  };

  return (
    <>
      <div className="background-plain">
        <IndexNavbar />
        <div className="container mt-5" style={{ paddingTop: '100px' }}>
          <div className="row">
            <div className="col-md-3">
              <div className="card">
                <div className="card-header">
                  <h4><b>정보를 입력해주세요</b></h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="userAge">나이</label>
                      <input 
                        type="number" 
                        className="form-control" 
                        id="userAge" 
                        placeholder="만 나이를 입력해주세요" 
                        value={age} 
                        onChange={e => setAge(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="userLocation">거주지(주소)</label>
                      <select
                        className="form-control" 
                        id="userLocation" 
                        value={location} 
                        onChange={e => setLocation(e.target.value)}
                      >
                        <option value="">지역을 선택해주세요</option>
                        <option value="경기">경기</option>
                        <option value="서울">서울</option>
                        <option value="충북">충북</option>
                        <option value="전북">전북</option>
                        <option value="세종">세종</option>
                        <option value="경북">경북</option>
                        <option value="경남">경남</option>
                        <option value="대전">대전</option>
                        <option value="강원">강원</option>
                        <option value="충남">충남</option>
                        <option value="울산">울산</option>
                        <option value="부산">부산</option>
                        <option value="전남">전남</option>
                        <option value="광주">광주</option>
                        <option value="인천">인천</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="userGender">성별</label>
                      <select 
                        className="form-control" 
                        id="userGender" 
                        value={gender} 
                        onChange={e => setGender(e.target.value)}
                      >
                        <option value="">성별을 선택해주세요</option>
                        <option value="male">남성</option>
                        <option value="female">여성</option>
                        <option value="other">기타</option>
                      </select>
                    </div>
                    <button type="submit" className="btn btn-primary">제출</button>
                  </form>
                </div>
              </div>
              {firestoreData && (
                <div className="card mt-4">
                  <div className="card-header">
                    <h4><b>제출된 정보</b></h4>
                  </div>
                  <div className="card-body">
                    <p>나이: {firestoreData.age}</p>  
                    <p>거주지: {firestoreData.location}</p>
                    <p>성별: {firestoreData.gender}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-9">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4><b>맞춤 자립 지원 정보</b></h4>
                  <button 
                    className="btn btn-light-green"
                    onClick={() => setToggle(!toggle)}
                    style={{
                      backgroundColor: 'lightgreen',
                      border: 'none',
                      padding: '10px',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    {toggle ? "자립정보on 보기" : "온통청년 보기"}
                  </button>
                </div>
                <div className="card-body horizontal-scroll-container">
                  {toggle ? (
                    <div className="d-flex flex-row">
                      {airtableData1.map(record => (
                        <div className="capsule-box" key={record.id}>
                          <h4 className="capsule-title"><b>{record.fields.Title}</b></h4>
                          <hr />
                          <div className="capsule-content">
                            <b><p>모집여부 : {record.fields.whether}</p>
                            <p>분야 : {record.fields.field}</p>
                            <p>지역 : {record.fields.region}</p>
                            <p>담당 기관/지역 : {record.fields.Name}</p>
                            <pre> </pre>  
                            <p>상세 정보 : {record.fields.Text}</p></b>
                          </div>
                        </div>
                      ))}
                    </div>
                    
                  ) : loading ? (
                    <p>Loading...</p>
                  ) : error ? (
                    <p>{error}</p>
                  ) : (
                    <div className="d-flex flex-row">
                      {airtableData.map(record => (
                        <div className="capsule-box" key={record.id}>
                          <h4 className="capsule-title"><b>{record.fields.Title}</b></h4>
                          <hr />
                          <div className="capsule-content">
                            <b><p>모집여부 : {record.fields.whether}</p>
                            <p>분야 : {record.fields.field}</p>
                            <p>기관 : {record.fields.instituion}</p>
                            <p>지역 : {record.fields.region}</p>
                            <p>기간 : {record.fields.Date1} {record.fields.Date2}</p>
                            <p>대상 : {record.fields.target}</p>
                            <pre> </pre>  
                            <p>상세 정보 : {record.fields.Text}</p></b>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPage;
