import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ChatInterface from "components/chatinterface";
import "../gochat.css"; // Import custom CSS file for styling


function Gochat() {
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

  return (
    <>
      <div className="background-image">
        <IndexNavbar />
        <ChatInterface />
        
      </div>
    </>
  );
}

export default Gochat;
