import React, { useState, useEffect, useRef } from "react";
import backgroundImage from "../imgs/images.png"; 

const ChatInterface = () => {
  const email = localStorage.getItem('email');
  const gmail = localStorage.getItem('googleEmail');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever the messages array changes
  }, []);

  useEffect(() => {
    const loadVoiceflow = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      script.type = "text/javascript";
      script.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: '6601651f0dc59dfb0157804a' },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          render: {
            mode: 'embedded',
            target: document.getElementById('voiceflow-chat'),
          },
          // autostart: false,
        });
      };

      document.body.appendChild(script);
    };

    loadVoiceflow();
  }, []); // Empty dependency array to ensure this runs only once on mount

  return (
    <div className="chat-interface-container" style={{
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      maxWidth: '100vw',
      height: '100vh',
      display: "flex",
      flexDirection: "column",
      padding: '10px',
      paddingTop: '100px',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}>
      <div id="voiceflow-chat" style={{ flex: 1 }}></div> {/* Voiceflow chat target */}
      <div ref={messagesEndRef} />
    </div>
  );  
};

export default ChatInterface;
