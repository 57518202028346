/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


// core components
import AltNavbar from "components/Navbars/altnavbar"; 
import "../fonts/font.css"

function Userguide() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <AltNavbar />
      
      
      <div className="main">
        <div className="section text-center">
        <div id="container">
              <img
                  alt="..."
                  src={require("imgs/userguide.png")}
                  style = {{width : "100%"}}
              />
          </div>
        </div>
        
      </div>
      
    </>

  );
  

}

export default Userguide;
