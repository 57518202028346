import React, { useState } from "react";
import emailjs from 'emailjs-com';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import AltNavbar from "components/Navbars/altnavbar"; 
import "../fonts/font.css"
import MyGallery from "components/gallery";
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  
  const storedEmail = localStorage.getItem('email');
  const storedgmail = localStorage.getItem('googleEmail');
  const [formData, setFormData] = useState({
    name: '',
    message: ''
  });

  const photos = [
    { src: "imgs/website1.jpg", width: 800, height: 600 },
    { src: "imgs/website2.jpg", width: 1600, height: 900 },
    { src: "imgs/그림1.jpg", width: 1600, height: 900 },
    { src: "imgs/그림2.jpg", width: 1600, height: 900 },
    { src: "imgs/그림12.jpg", width: 1600, height: 900 },

  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    
    const fromEmail = storedEmail || storedgmail || "Login needed";

    
    const templateParams = {  
      from_name: formData.name,
      from_email: fromEmail,
      message: formData.message,
      to_email: 'dreamcraft0220@gmail.com' // replace with your email address
    };

    if(fromEmail=="Login needed"){
      alert('먼저 로그인 해주세요.');
    }
    else {
      emailjs.send(
        'service_epcuaoa', // replace with your service ID
        'template_g260jkf', // replace with your template ID
        templateParams,
        'iIdoEWqfQHLbjcCp6' // replace with your user ID
      ).then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('메세지가 전송되었습니다.'); 
        // Handle success, e.g., show a notification or clear the form
        setFormData({ name: '', email: '', message: '' });
      }).catch((error) => {
        console.error('FAILED...', error);
        alert('오류가 발생했습니다. 잠시 후 시도해주세요.'); 
        // Handle error, e.g., show an error message
      });
    }

    
  };

  return (
    <>
      <AltNavbar />
      
      <div className="main">
        <div id="container">
              <img
                  alt="..."
                  src={require("imgs/intro.png")}
                  style = {{width : "100%"}}
              />
          </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title"><b>프로젝트 팀 소개</b></h2>
            <Row>
              <Col md="3">
                <Card className="card-profile card-plain" >
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("imgs/증명사진.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">홍승재</CardTitle>
                        <h6 className="card-category">Software Engineer</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      민족사관고등학교에 재학중인 국제계열 3학년입니다. 평소 AI와 데이터과학에 관심이 많으며, 추후 그 기술이 사회에 미치는 영향에 대해 연구하고 싶어합니다. 
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                
                  </CardFooter>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-profile card-plain" >
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("imgs/423147444_367828326164941_785476776804717063_n.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">조민아</CardTitle>
                        <h6 className="card-category">Project Organizer</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                    민족사관고등학교에 재학중인 국제계열 3학년입니다. 경제와 사회적 불평등 사이의 관계에 대해 탐구하고자 합니다.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    
                  </CardFooter>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-profile card-plain" >
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("imgs/427960173_933977211466882_4488161705741494961_n.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">이예준</CardTitle>
                        <h6 className="card-category">Marketing Manager</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                    민족사관고등학교에 재학중인 국제계열 3학년입니다. 장래희망은 사회과학과 역사를 연구하는 사학자입니다.</p>
                  </CardBody>
                  <CardFooter className="text-center">
              
              
                  </CardFooter>
                </Card>
              </Col>
              
                <Col md="3" >
                  <Card className="card-profile card-plain" > 
                    <div className="card-avatar">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          src={require("imgs/428022555_378164368322694_6481519098321533060_n.jpg")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="author">
                          <CardTitle tag="h4">이지후</CardTitle>
                          <h6 className="card-category">Data Analyst</h6>
                        </div>
                      </a>
                      <p className="card-description text-center">
                      민족사관고등학교에 재학중인 국내계열 3학년입니다. 경제학과 통계학을 접목하는 것에 관심이 많습니다.</p>
                    </CardBody>
                    <CardFooter className="text-center">
                    
                    </CardFooter>
                  </Card>
                </Col>
              
            </Row>
          </Container>
        </div>
        <div> 
          <h1 style={{color : "black", fontWeight : "bold", textAlign:"center"}}> Gallery </h1>
          <br></br>
          <br></br>
          <br></br>
          <MyGallery></MyGallery>
        </div>
        <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center"><b>의견을 남겨주세요</b></h2>
                <Form className="contact-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <label>이름</label>
                      <InputGroup>
                        
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        
                        <Input
                          placeholder="Name"
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Col>
            
                  </Row>
                  <label>메세지</label>
                  <Input
                    placeholder="Tell us your thoughts and feelings..."
                    type="textarea"
                    rows="4"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" size="lg" style={{ backgroundColor: '#78fb6f', borderColor: '#78fb6f' }} type="submit">
                        보내기
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
    </>

  );
}

export default LandingPage;
