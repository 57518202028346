// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDZp2GxQktNO-bEGU7yyvP92djhb7M3LcQ",
  authDomain: "dreamcraft-13de9.firebaseapp.com",
  databaseURL: "https://dreamcraft-13de9-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dreamcraft-13de9",
  storageBucket: "dreamcraft-13de9.appspot.com",
  messagingSenderId: "193613957398",
  appId: "1:193613957398:web:75c44e203b97e64fd52837",
  measurementId: "G-SK897JNW01"
  
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authService = getAuth(app); // 로그인 모듈
export const db = getFirestore(app) // DB 정보
