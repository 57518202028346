import React from "react";
import backgroundimage from "imgs/whitee.png";
import bottomImage from "imgs/nightsky.jpg"; // Make sure to replace with the actual image path
import videoFile from "imgs/3971351-uhd_3840_2160_25fps.mp4";

function WhiteHeader() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundimage})`,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '13vw',
            background: 'linear-gradient(45deg, #b2b8dc, #d5abc1)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center',
            width: '100%',
            zIndex: 1,
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          DREAMCRAFT
        </div>
        <video
          style={{
            position: 'absolute',
            bottom: '0',
            width: '100%',
            height: '50%',
            objectFit: 'cover',
            zIndex: 0,
          }}
          autoPlay
          loop
          muted
        >
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        
      </div>
    </>
  );
}

export default WhiteHeader;
