import React, { useState } from "react";
// reactstrap components
import { Button, Card, Form, Input, Container, Row, Col } from "reactstrap";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { authService } from './firebase';
import AltNavbar from "components/Navbars/altnavbar";

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newAccount, setNewAccount] = useState('');
  const [error, setError] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const toggleAccount = () => setNewAccount((prev) => !prev);

  const onChange = (e) => {
    const { target: { name, value } } = e;
    if (name === "email") setEmail(value);
    else if (name === "password") setPassword(value);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      let data;
      if (newAccount) data = await createUserWithEmailAndPassword(authService, email, password);
      else data = await signInWithEmailAndPassword(authService, email, password);
      localStorage.setItem("email", email);

      setShowSuccessMessage(true);
      if (document.referrer && new URL(document.referrer).origin === window.location.origin) {
        window.history.back(); return false;
      } else {
        window.location.href = "./index.html";
      }
    } catch (error) {
      handleError(error);
    }
  }

  const handleError = (error) => {
    console.log(error, error.code);
    if (error.code === "auth/email-already-exists") {
      setError("이미 사용 중인 이메일입니다.");
    } else if (error.code === "auth/invalid-password") {
      setError("비밀번호는 6글자 이상이어야 합니다.");
    } else if (error.code === "auth/invalid-email") {
      setError("잘못된 이메일 형식입니다.");
    } else if (error.code === "auth/internal-error") {
      setError("잘못된 요청입니다.");
    } else if (error.code === "auth/user-not-found") {
      setError("Incorrect email or password.");
    } else {
      setError("회원가입에 실패 하였습니다.");
    }
  }

  const handleGoogleSignIn = async (e) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const googleEmail = user.email;
      localStorage.setItem('googleEmail', googleEmail);
      setShowSuccessMessage(true);
      if (document.referrer && new URL(document.referrer).origin === window.location.origin) {
        window.history.back(); return false;
      } else {
        window.location.href = "./index.html";
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <AltNavbar />
      <div
        className="page-header"
        style={{
          backgroundImage: "url(" + require("assets/img/login-image.jpg") + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <div style={{ padding: '50px' }}></div>
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto">
                <h3 className="title mx-auto"><b>환영합니다</b></h3>
                <div className="social-line text-center">
                  <Button
                    className="btn-neutral btn-just-icon mr-1"
                    color="google"
                    href="#pablo"
                    onClick={handleGoogleSignIn}
                  >
                    <i className="fa fa-google-plus" />
                  </Button>
                </div>
                <div style={{ padding: '10px' }}></div>
                <span style={{ textDecoration: 'underline' }} onClick={toggleAccount}>{newAccount ? "이미 계정이 있으신가요?" : "계정 새로 만들기"}</span>
                <Form className="register-form" onSubmit={onSubmit}>
                  <label>이메일</label>
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    required
                    value={email}
                    onChange={onChange}
                  />
                  <label>비밀번호</label>
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    required
                    value={password}
                    onChange={onChange}
                  />
                  <Button block className="btn-round" color="danger" type="submit">
                    {newAccount ? "회원가입" : "로그인"}
                  </Button>
                </Form>
                {showSuccessMessage && (
                  <div>
                    {window.alert("You have successfully logged in!\nEmail: " + email + "\nPassword: " + password)}
                    {setTimeout(() => {
                      setShowSuccessMessage(false);
                    }, 7000)}
                  </div>
                )}
                <div className="forgot">
                  <Button
                    className="btn-link"
                    color="danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    비밀번호를 까먹으셨나요?
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="footer register-footer text-center">
        </div>
      </div>
    </>
  );
}
export default RegisterPage;
