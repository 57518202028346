import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Dropdown from 'react-bootstrap/Dropdown';
import backgroundimage from 'imgs/logotrans.png';
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { useLocation } from "react-router-dom";

function AltNavbar() {
  const location = useLocation();
  const storedEmail = localStorage.getItem('email');
  const storedgmail = localStorage.getItem('googleEmail');
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const auth = getAuth();
  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  
    return function cleanup() {
      unsubscribe(); // Unsubscribe from the onAuthStateChanged listener
    };
  }, [auth]); // Run whenever auth changes
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  
  const handleLogout = async () => {
    try {
      await auth.signOut(); // Sign out using the Firebase authentication object.
      setIsLoggedIn(false);
      localStorage.removeItem('email'); // Clear stored email
      localStorage.removeItem('googleEmail'); // Clear stored Google email
      if (window.location.href.indexOf('gochat') !== -1) {
        window.location.href = '/index';
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleGoChat = () => {
    if (isLoggedIn) {
      window.location.href = '/gochat'; // Navigate to chat page if logged in
    } else {
      alert('Login first!'); // Show an error message if not logged in
    }
  };

  const textStyle = { color: 'black', fontSize: '15px' };

  return (
    <Navbar className={classnames("fixed-top", "white")} expand="lg"> {/* Set navbar color to white */}
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            target="_blank"
            title="Coded by Creative Tim"
          >
            <img
              src={backgroundimage} // Correct syntax for image source
              alt="DreamCraft"
              style={{ height: '30px', width: 'auto' }}
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem className="mx-2">
              <NavLink 
                data-placement="bottom"
                href="/index"
                style={textStyle}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink 
                data-placement="bottom"
                href="/landing-page"
                style={textStyle}
              >
                About Us
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink 
                data-placement="bottom"
                href="/userguide"
                style={textStyle}
              >
                UserGuide
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink 
                data-placement="bottom"
                href="/feedback"
                style={textStyle}
              >
                User Feedback
              </NavLink>
            </NavItem>
            {isLoggedIn ? ( // Conditional rendering for Logout or Login button
              <NavItem className="mx-2">
                <NavLink 
                  style={{ cursor: 'pointer', ...textStyle }} 
                  onClick={handleLogout}
                >
                  Logout
                </NavLink>
              </NavItem>
            ) : (
              <NavItem className="mx-2">
                <NavLink data-placement="bottom" href="/register-page" style={textStyle}>Login</NavLink>
              </NavItem>
            )}
            <NavItem className="mx-2">
              <div ref={dropdownRef}>
                <Dropdown show={dropdownOpen} onClick={toggleDropdown}>
                  <Dropdown.Toggle 
                    variant="success" 
                    id="dropdown-basic" 
                    style={{ backgroundColor: 'transparent', borderColor: 'transparent', ...textStyle }}
                  >
                    Menu
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item style={textStyle}>
                      {isLoggedIn
                        ? storedEmail // Display stored email if logged in with email
                          ? storedEmail
                          : storedgmail // Display stored Google email if logged in with Google
                            ? storedgmail
                            : "Login needed"
                        : "Login needed"}
                    </Dropdown.Item>
                    <Dropdown.Item href="/mypage" style={textStyle}>My Page</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </NavItem>
            <NavItem className="mx-2">
              <Button
                className="btn-round"
                style={{ backgroundColor: '#78fb6f', borderColor: '#78fb6f', fontSize: '15px', color: 'white' }}
                onClick={handleGoChat}
              >
                <i className="nc-icon nc-spaceship"></i> Go Chat
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default AltNavbar;
