/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import AltNavbar from "components/Navbars/altnavbar"; 
import "../fonts/font.css"

function Feedback() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <AltNavbar />
      
      <div className="main">
        <div className="section text-center">
          <div id="container">
            <img
              alt="..."
              src={require("imgs/second fe.jpg")}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        {/* Feedback Text Container */}
        <div className="section text-left">
          <div className="container">
            <h2 style={{ fontWeight: 'bold', color : 'black'}}>&lt;Feedback from Campaigners on DreamCraft&gt;</h2>

            <br></br>
            
            <p style={{color:'black'}}>Hello, DREAMCRAFT team members,</p>
            <p>First of all, thank you for your time and effort. Based on the experiences of using the generative AI chatbot developed by you, I would like to share my impressions and feedback.</p>
            <br></br>
            <div className="feedback-section">
              <h4 style={{ backgroundColor: '#DEFE88', color: 'black', fontWeight: 'bold' }}>1. Accessibility to Financial Information:</h4>
              <p style={{color:'black'}}>The most noticeable advantage when using the AI chatbot was the ease of accessing information. Traditional financial education often involves lectures or presentations, which took me a long time to find the information I needed. On the other hand, the chatbot could quickly and accurately provide the necessary information by immediately answering questions. For example, it was helpful in understanding complex questions about topics like stock and bond calculations by providing simplified explanations.</p>
            </div>
            <br></br>

            <div className="feedback-section">
              <h4 style={{ backgroundColor: '#DEFE88', color: 'black', fontWeight: 'bold' }}>2. Learning Efficiency:</h4>
              <p style={{color:'black'}}>Additionally, the use of the AI chatbot significantly improved the efficiency of learning. The traditional method often involved passively receiving information, whereas using the chatbot allowed me to actively engage by asking questions and receiving answers, making the learning content more memorable. This interactive learning method was particularly effective in inducing behavioral changes. For example, hearing explanations on how to manage my monthly expenses and analyzing my consumption patterns proved to be highly practical.</p>
            </div>
            <br></br>
            <div className="feedback-section">
              <h4 style={{ backgroundColor: '#DEFE88', color: 'black', fontWeight: 'bold' }}>3. Areas for Improvement:</h4>
              <p style={{color:'black'}}>While using it, I found several areas that need improvement. First, some explanations on the use of advanced and higher-level functions seemed too simplified. For instance, a more detailed explanation of options and real-life scenarios, such as derivative transactions, would be beneficial. Second, when dealing with specific questions on complex topics, the chatbot sometimes failed to clarify the timeline, making it time-consuming to obtain the desired information. Improving its natural language processing ability to better understand the intent behind the questions would be beneficial.</p>
            </div>
            <br></br>
            <div className="feedback-section">
              <h4 style={{ backgroundColor: '#DEFE88', color: 'black', fontWeight: 'bold' }}>4. User Interface:</h4>
              <p style={{color:'black'}}>I believe that some improvements could be made to the chatbot’s user interface (UI). For example, adding a feature that allows users to easily search through conversation history or a FAQ section that compiles frequently asked questions could enhance the user experience. Additionally, having a function that provides simple definitions for frequently asked financial terms could help even finance beginners understand better.</p>
            </div>
            <br></br>
            <div className="feedback-section">
              <h4 style={{ backgroundColor: '#DEFE88', color: 'black', fontWeight: 'bold' }}>5. Willingness to Reuse and Additional Feedback:</h4>
              <p style={{color:'black'}}>Regarding the willingness to reuse, it is quite positive. Especially in situations where financial knowledge needs to be accumulated steadily, the AI chatbot is expected to be very useful. However, if the mentioned areas for improvement are addressed, more people will likely find this tool useful. If additional content beyond simple finance is included, it will become an excellent learning tool that can be used in various fields.</p>
            </div>
            <br></br>
            <p style={{color:'black'}}>Thank you.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedback;
