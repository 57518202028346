import ImageGallery from "react-image-gallery";
import '../App.css'
import img1 from "../imgs/website1.jpg";
import img2 from "../imgs/website2.jpg";
import img3 from "../imgs/그림1.png";

import img4 from "../imgs/그림2.png";
import img5 from "../imgs/그림12.png";
import img6 from "../imgs/457576324_8156965667713147_3548640021011692151_n.jpg"
import img7 from "../imgs/458338793_524585520043843_5560103723976452597_n.jpg"

import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components


const images = [
  {
    original: img5,
    thumbnail: img5
  },
  {
    original: img7,
    thumbnail: img7,
  },
  {
    original: img1,
    thumbnail: img1,
  },

  {
    original: img3,
    thumbnail: img3,
  },

  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img2,
    thumbnail: img2,
  },
];

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}

export default MyGallery;